import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
 
class Footer extends Component {
  render() {
    return (
      <div style={{backgroundColor:'#000'}}>{/* <div style={{backgroundColor:'#282c34'}}> */}
       <Container className="square border-top  border-secondary"  fluid>
        <Row>
            <div className="text-center">
            FOOTER (C)
            </div>        
        </Row>
       </Container >
      </div>
    );
  }
}
 
export default Footer;