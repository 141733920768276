import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Header from './Components/Header';
import Footer from './Components/Footer';
import TralldackChart from './Components/TralldackChart';
import SuppliesTable from './Components/SuppliesTable';
import SuppliesTable2 from './Components/SuppliesTable2';
import ExampleLineChart from './Components/ExampleLineChart'; // Import the new component
import ShortText from './Components/ShortText';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import TralldackChart2 from './Components/TralldackChart2';
import TralldackChart3 from './Components/TralldackChart3';
import './index.css';
import IndexSummary from './Components/IndexSummary';
import IndexSummary2 from './Components/IndexSummary2';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header />
    <Container className="px-3"> {/* Add the container with padding */}
    <ShortText />
{/*     <IndexSummary /> */}
    <IndexSummary2 />
{/*     <TralldackChart />
    <TralldackChart2 /> */}
    <TralldackChart3 />
    <SuppliesTable2 />
{/*     <ExampleLineChart />  */}
    </Container>
    <Footer />
  </React.StrictMode>
);

reportWebVitals();
