import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
/* import Nav from 'react-bootstrap/Nav'; */
import Navbar from 'react-bootstrap/Navbar';

class Header extends Component {
    render() {
        return (
            <>
                <Navbar style={{ color: '#fff', backgroundColor: '#000' }}> {/* <Navbar bg="dark" data-bs-theme="dark"></Navbar> */}
                    {/* <Container className="square border-bottom border-secondary"> */}
                    <Container className="square">
                        <Navbar.Brand  style={{ color: '#fff', backgroundColor: '#000' }}>bindex</Navbar.Brand>
                        {/* <Nav className="me-auto">
                            <Nav.Link href="#home">Hem</Nav.Link>
                            <Nav.Link href="#features">Om b-index</Nav.Link>
                            <Nav.Link href="#pricing">Frågor</Nav.Link>
                            <Nav.Link href="#pricing">Kontakt</Nav.Link>
                        </Nav> */}
                    </Container>
                </Navbar>
            </>
        );
    }
}

export default Header;