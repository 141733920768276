import React from 'react';

const ShortText = () => {
  return (
    <div style={{ textAlign: 'left', marginTop: '20px' }}>
{/*       <h2>This is a short text</h2> */}
      <p><b>ByggIndex</b> - din guide till prisutvecklingen byggmaterial. 
        Här hittar du information om hur byggkostnaderna har förändrats över tid, vilket hjälper dig att förstå om det är en bra tid att påbörja ditt byggprojekt. 
        Utforska våra diagram och få en djupare inblick i byggmarknaden. 
        Här får du den kunskap du behöver för att göra smarta, kostnadseffektiva val.</p>
    </div>
  );
};

export default ShortText;