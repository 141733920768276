import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Prisindex för trädäck',
    },
  },
  elements: {
    line: {
      tension: 0.4, // Adjust the tension for rounded lines (0 = straight lines, 1 = fully rounded lines)
      borderWidth: 2,
      borderCapStyle: 'round',
      borderJoinStyle: 'round',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
  labels,
  datasets: [
    {
      label: 'Beijer Bygg',
      data: [100, 98, 103, 110, 105, 102, 95],
      borderColor: 'rgb(0,0,0)',
      backgroundColor: 'rgba(254,209,65, 1)',
    },
    {
      label: 'Byggmax',
      data: [100, 103, 166, 81, 56, 55, 40],
      borderColor: 'rgb(255,230,50)',
      backgroundColor: 'rgba(235,0,30, 1)',
    },
    {
      label: 'Bauhaus',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 200 })),
      borderColor: 'rgb(204,0,0)',
      backgroundColor: 'rgb(255,255,255, 1)',
    },
    {
      label: 'Sammanvägt',
      data: [100, 59, 80, 81, 56, 55, 40],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

function TralldackChart() {
  return <Line options={options} data={data} />;
}

export default TralldackChart;
