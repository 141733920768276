import React from 'react';
import ReactECharts from 'echarts-for-react';

const TralldackChart3 = () => {
  const generateRandomData = () => {
    const today = new Date();
    const startDate = new Date('2023-01-01');
    const data = [];
    let value = Math.floor(Math.random() * (150 - 75 + 1)) + 75;
    let minValue = value; // Track the lowest value in the data

    const months = [
      'jan', 'feb', 'mars', 'april', 'maj', 'juni',
      'juli', 'aug', 'sep', 'okt', 'nov', 'dec'
    ];

    for (let date = startDate; date <= today; date.setDate(date.getDate() + 1)) {
      const dayOfMonth = date.getDate();
      const month = months[date.getMonth()];
      const formattedDate = `${dayOfMonth} ${month}`;
      const change = Math.floor(Math.random() * 11) - 5;
      value = Math.max(75, Math.min(150, value + change));
      minValue = Math.min(minValue, value);

      data.push({
        name: formattedDate,
        value,
      });
    }

    return { data, minValue };
  };

  const { data, minValue } = generateRandomData();

  const options = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: 'category',
      data: data.map((item) => item.name),
      axisLabel: {
        color: '#8B919C',
      },
      axisLine: {
        lineStyle: {
          color: '#8B919C',
        },
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#8B919C',
      },
      axisLine: {
        lineStyle: {
          color: '#8B919C',
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#8B919C',
          type: 'dotted',
        },
      },
      min: Math.floor(minValue / 10) * 10 - 5, // Adjust the minimum value based on the lowest value in the data
    },
    series: [
      {
        data,
        type: 'line',
        smooth: true,
        lineStyle: {
          color: '#fff',
          width: 2,
          type: 'solid',
        },
        itemStyle: {
          color: '#8B919C',
        },
        showSymbol: false,
      },
    ],
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#000',
      textStyle: {
        color: '#8B919C',
      },
    },
  };

  return (
    <div style={{ background: '#000', padding: '16px' }}>
      <ReactECharts option={options} />
    </div>
  );
};

export default TralldackChart3;
