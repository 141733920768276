import React from 'react';

const BuildingSuppliesTable2 = () => {
  return (
    <div className="bg-black text-white w-full" style={{paddingTop:"16px"}}>
      <p><b className="text-gray-400 text-lg mb-3" style={{color:"#9CA3AF"}}>Byggmaterial i index</b></p>
      <table className="w-full" style={{margin:0, width:'100%'}}>
        <thead>
          <tr>
            <th className="th-num hidden sm:table-cell"></th>
            <th className="">Material</th>
            <th className="th-num">Antal</th>
            <th className="th-num"></th>
          </tr>
        </thead>
        <tbody>
          <tr className="clickable">
            <td className="td-num hidden sm:table-cell">1</td>
            <td>45x145 Regel</td>
            <td className="td-num">42m</td>
            <td className="td-num">Länk</td>
          </tr>
          <tr className="clickable">
            <td className="td-num hidden sm:table-cell">2</td>
            <td>95x95 Stolpe</td>
            <td className="td-num">20m</td>
            <td className="td-num">Länk</td>
          </tr>
          <tr className="clickable">
            <td className="td-num hidden sm:table-cell">3</td>
            <td>28x120 Trall</td>
            <td className="td-num">600m</td>
            <td className="td-num">Länk</td>
          </tr>
          <tr className="clickable">
            <td className="td-num hidden sm:table-cell">4</td>
            <td>Big Dog Skruv 8x80</td>
            <td className="td-num">40st</td>
            <td className="td-num">Länk</td>
          </tr>
          <tr className="clickable">
            <td className="td-num hidden sm:table-cell">5</td>
            <td>Trallskruv 4,2x55</td>
            <td className="td-num">800st</td>
            <td className="td-num">Länk</td>
          </tr>
          <tr className="clickable">
            <td className="td-num hidden sm:table-cell">6</td>
            <td>Konstruktionsskruv 6x80</td>
            <td className="td-num">50st</td>
            <td className="td-num">Länk</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BuildingSuppliesTable2;
