import React from 'react';

const IndexSummary2 = () => {
  return (
    <div className="index-summary" style={{paddingTop:"16px", paddingBottom:"16px"}}>
      <div className="left-half">
        <h2>99.92</h2>
      </div>
      <div className="right-half">
        <div className="index-column">
        <p style={{margin:0}}>30 jun</p>
          <h4 style={{color:"#60A5FA"}}>0.83%</h4>
        </div>
        <div className="index-column">
          <p style={{margin:0}}>i år</p>
          <h4 style={{color:"#F87171"}}>-0.08%</h4>
        </div>
      </div>
    </div>
  );
};

export default IndexSummary2;
